import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

// Components
import Container from "components/container"
import WebinarBody from "components/webinar/body"

// Downloads
import PesentationSlides from "assets/downloads/sexual-health-test/webinar-replay/2023_06_07-Park-JUCM-STI-Webinar.pdf"

const StyledContainer = styled(Container)`
  max-width: 1100px;
  overflow: hidden;
  padding: 0px;
`

const BodyLanding = props => {

	return (
		<StyledContainer>
			<WebinarBody
				viewAllowed={props.viewAllowed}
			>
				<div className="flexBox">
					<div className="block block--left">
						<div className="block__content">
							<h2 data-aos="fade-up">Overview</h2>
							<p data-aos="fade-up">STIs are currently at historic highs in the US, with epidemic increases observed for both gonorrhea and syphilis.
								STI expert and author Ina Park, MD, MS  will discuss the latest disease trends in STI incidence and  antibiotic resistance, as well
								as CDC guidelines for diagnosis and treatment of the most common STIs seen in urgent care settings.
							</p>
						</div>
					</div>

					<div className="block block--right">
						<div className={(props.pageName === 'display' | props.viewAllowed === false) ? "accessBox show" : "accessBox"}>
							<h3 data-aos="fade-up">Access Includes:</h3>
							<ul data-aos="fade-up">
								<li>Video recording replay</li>
								<li>Presentation Slides (PDF)</li>
							</ul>
						</div>
						<div className={(props.pageName === 'download') ? "downloadBox show showPDF" : "downloadBox"}>
							<h3 data-aos="fade-up">Download</h3>
							<p data-aos="fade-up"><a href={PesentationSlides} target="_blank" rel="noopener noreferrer">Presentation Slides (PDF)</a></p>
						</div>
					</div>
				</div>

				<div className="block block--left">
					<div className="block__content presenter">
						<h2 data-aos="fade-up">About the Presenter</h2>

						<div className="presenterBio" data-aos="fade-up">
							<StaticImage
								src="../../../../assets/images/webinar-replay/ina-park.jpg"
								width={200}
								alt="Dr. Ina Park"
								className="presenter__image"
							/>
							<p data-aos="fade-up">Dr. Ina Park, MD, MS is Professor, Department of Family and Community Medicine and Department of Obstetrics,
								Gynecology, and Reproductive Sciences at UCSF School of Medicine, as well as a Medical Consultant, Division of STD Prevention at the
								Centers for Disease Control and Prevention.  Additionally, she has authored the book Strange Bedfellows: Adventures in the Science,
								History, and Surprising Secrets of STDs.
							</p>
						</div>
					</div>
				</div>
			</WebinarBody>
		</StyledContainer>
	)
}

class FullBody extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			viewAllowed: false
		}
	}

	componentDidMount = props => {
		if (localStorage.getItem(this.props.localStorageID)) {
			this.setState({
				viewAllowed: true
			})
		}
	}

	render = () => (
		<BodyLanding
			pageName={this.props.pageName}
			viewAllowed={this.state.viewAllowed}
			localStorageID={this.props.localStorageID}
		/>
	)
}

export default FullBody
